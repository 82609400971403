import Door1bkg from "./img/1.jpg"
import Door2bkg from "./img/2.jpg"
import Door3bkg from "./img/3.jpg"
import Door4bkg from "./img/4.jpg"
import Door5bkg from "./img/5.jpg"
import Door6bkg from "./img/6.jpg"
import Door7bkg from "./img/7.jpg"
import Door8bkg from "./img/8.jpg"
import Door9bkg from "./img/9.jpg"
import Door10bkg from "./img/10.jpg"
import Door11bkg from "./img/11.jpg"
import Door12bkg from "./img/12.jpg"
import Door13bkg from "./img/13.jpg"
import Door14bkg from "./img/14.jpg"
import Door15bkg from "./img/15.jpg"
import Door16bkg from "./img/16.jpg"
import Door17bkg from "./img/17.jpg"
import Door18bkg from "./img/18.jpg"
import Door19bkg from "./img/19.jpg"
import Door20bkg from "./img/20.jpg"
import Door21bkg from "./img/21.jpg"
import Door22bkg from "./img/22.jpg"
import Door23bkg from "./img/23.jpg"
import Door24bkg from "./img/24.jpg"

const shuffle = a => {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  };
  
  export const hatchArray = [
    {
      id: "hatch-1",
      nr: 1,
      img: Door1bkg,
      text: "GFT \n +60%",


      open: false
    },
    {
      id: "hatch-2",
      nr: 2,
      img: Door2bkg,
      text: "ORDI \n +46%",
      open: false
    },
    {
      id: "hatch-3",
      nr: 3,
      img: Door3bkg,
      text:
      "AST \n +41%",
      open: false
    },
    {
      id: "hatch-4",
      nr: 4,
      img: Door4bkg,
      text: "IOTX \n +52%",
      open: false
    },
    {
      id: "hatch-5",
      nr: 5,
      img: Door5bkg,
      text: "BAKE \n +77%",
      open: false
    },
    {
      id: "hatch-6",
      nr: 6,
      img: Door6bkg,
      text:
      "BTTC \n +72%",
      open: false
    },
    {
      id: "hatch-7",
      nr: 7,
      img: Door7bkg,
      text:
        "JTO \n +49%",
      open: false
    },
    {
      id: "hatch-8",
      nr: 8,
      img: Door8bkg,
      text:
      "QI \n +56%",
      open: false
    },
    {
      id: "hatch-9",
      nr: 9,
      img: Door9bkg,
      text:
      "ARKO \n +31%",
      open: false
    },
    {
      id: "hatch-10",
      nr: 10,
      img: Door10bkg,
      text:
      "QI \n +34%",
      open: false
    },
    {
      id: "hatch-11",
      nr: 11,
      img: Door11bkg,
      text:
      "QI \n +67%",
      open: false
    },
    {
      id: "hatch-12",
      nr: 12,
      img: Door12bkg,
      text:
      "1000SATS \n +127%",
      open: false
    },
    {
      id: "hatch-13",
      nr: 13,
      img: Door13bkg,
      text:
      "FUN \n +92%",
      open: false
    },
    {
      id: "hatch-14",
      nr: 14,
      img: Door14bkg,
      text:
      "1000SATS \n +47%",
      open: false
    },
    {
      id: "hatch-15",
      nr: 15,
      img: Door15bkg,
      text:
      "POLS \n +35%",
      open: false
    },
    {
      id: "hatch-16",
      nr: 16,
      img: Door16bkg,
      text:
      "WLD \n +54%",
      open: false
    },
    {
      id: "hatch-17",
      nr: 17,
      img: Door17bkg,
      text:
      "ALPHA \n +24%",
      open: false
    },
    {
      id: "hatch-18",
      nr: 18,
      img: Door18bkg,
      text:
      "ACE \n +3785%",
      open: false
    },
    {
      id: "hatch-19",
      nr: 19,
      img: Door19bkg,
      text:
      "DATA \n +90%",
      open: false
    },
    {
      id: "hatch-20",
      nr: 20,
      img: Door20bkg,
      text:
        "BAKE \n +49%",
      open: false
    },
    {
      id: "hatch-21",
      nr: 21,
      img: Door21bkg,
      text:
      "RAY \n +56%",
      open: false
    },
    {
      id: "hatch-22",
      nr: 22,
      img: Door22bkg,
      text:
      "Not available yet \n :(",
      open: false
    },
    {
      id: "hatch-23",
      nr: 23,
      img: Door23bkg,
      text:
      "Not available yet \n :(",
      open: false
    },
    {
      id: "hatch-24",
      nr: 24,
      img: Door24bkg,
      text:
      "Not available yet \n :(",
      open: false
    },
  ];
  
  export const createCalendar = () => shuffle(hatchArray);