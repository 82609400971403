import React, { useState, useEffect } from "react";
import { createGlobalStyle } from "styled-components";
import { StyledApp } from "./AppStyles";
import { createCalendar } from "./helpers";
import Door from "./door";
import Appbackground from "./img/calendar_backdrop.jpg"
import logo from './img/binance-logo.svg'; // Import your logo

import Card from './Card';



const GlobalStyle = createGlobalStyle`
  body {
    background: url(${Appbackground}) no-repeat center center fixed; 
    background-size: cover; // Ensure the image covers the entire viewport
    margin: 0; // Remove default margin
    height: 100%; // Full height
    width: 100%; // Full width
    overflow-x: hidden; // Prevent horizontal scrolling
  }

  header {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: black;
    color: yellow;
  }

  img.logo {
    margin-right: 10px;
    height: 50px;
  }
`;
function App() {
  const [doors, setDoors] = useState([]);

  

  useEffect(() => {
    
    const calendar = localStorage.calendar
      ? JSON.parse(localStorage.calendar)
      : createCalendar();

    setDoors(calendar);
  }, []);

  // Store calendar in localStorage
  useEffect(() => {
    
    doors.length && localStorage.setItem("calendar", JSON.stringify(doors));
  }, [doors]);

  const handleFlipDoor = id => {
    const updatedDoors = doors.map(door =>
      door.id === id ? { ...door, open: !door.open } : door
    );
    setDoors(updatedDoors);
  };

  return (
    <>
      <GlobalStyle />
      <header>
        <img src={logo} alt="Logo" className="logo" />
        <h1>Binance Advent Calendar</h1>
        </header>
        <div>
            <Card 
                title="How does it works?" 
                text="It's like an advent calendar, but for crypto! Make a wish and open the door to see which crypto mooned during that specific day of December. Obviously on Binance! Did you guessed correctly?" 
            />
        </div>
      <StyledApp>
        {doors.map(door => (
          <Door
            key={door.id}
            doorData={door}
            handleClick={handleFlipDoor}
          />
        ))}
      </StyledApp>

      
    </>
  );
}

export default App;