import React from 'react';

const Card = ({ title, text }) => {
    const cardStyle = {
        padding: '20px',
        margin: '20px',
        backgroundColor: '#000', // Black background
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        transition: '0.3s',
        borderRadius: '5px',
        color: '#FFF', // White text for the body
    };

    const titleStyle = {
        color: '#FFD700', // Yellow text for the title
    };

    return (
        <div style={cardStyle}>
            <h3 style={titleStyle}>{title}</h3>
            <p>{text}</p>
        </div>
    );
};

export default Card;
