import React from "react";
import { StyledDoor } from "./doorStyles";

const Door = ({ doorData: { id, nr, text, img, open }, handleClick }) => (
  <StyledDoor background={img} onClick={() => handleClick(id)}>
    <div className={open ? "front open" : "front"}>
      <p>{nr}</p>
    </div>
    <div className={open ? "back open" : "back"}>
        <p>
        {text.split('\n').map((line, index, array) => {
        // Apply special styling only to the second part
        if (index === 1) {
          return (
            <React.Fragment key={index}>
              <span style={{ fontWeight: 'bold', color: 'green', fontSize: '40px' }}>
                {line}
              </span>
              {index !== array.length - 1 && <br />}
            </React.Fragment>
          );
        }

        // Render the first part as normal
        return (
          <React.Fragment key={index}>
            {line}
            {index !== array.length - 1 && <br />}
          </React.Fragment>
        );
    })}

      </p>
    </div>
  </StyledDoor>
);

export default Door;